export const environment = {
  production: false,
  LOCAL_STORAGE_PREFIX: '',
  DOMAIN_SCHEME: 'https://',
  ENV: 'uat',
  DOMAIN: '',
  API_URL: 'https://uat-api.lyneopiscines.com/v1/',
  SERVER_URL: 'https://uat-api.lyneopiscines.com/v1',
  FILE_ENDPOINT: 'https://uat-api.lyneopiscines.com/v1/document/upload',
  DATA_ENDPOINT: 'https://uat-api.lyneopiscines.com/v1/fichier/',
  BACKOFFICE_URL: 'https://uat-backoffice.lyneopiscines.com/',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCvh9-t_H6UigBT--9yktsPK1rP0S0wkqE',
  CAPTCHA_KEY: '6Lec3-cUAAAAAOUEcINl6YNx7wpBN34atxfgvDtc',
};
